@import url(https://fonts.googleapis.com/css2?family=Akaya+Telivigala&family=Orbitron&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*,
p {
  padding: 0;
  border: 0;
  margin: 0;
  font-family: 'Akaya Telivigala', cursive;
}

.container {
  display: grid;
  grid-template-rows: 1fr 2fr;
  -webkit-align-items: space-around;
          align-items: space-around;
  height: 100vh;
  max-width: 520px;
  margin: 0 auto;
}

.headerText {
  -webkit-align-items: center;
          align-items: center;
  justify-items: center;
}
.flex {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  height: 100%;
}

.actualHeaderText {
  font-size: calc(50px + 2vmin);
}

.timeCounter p span {
  text-align: center;
  font-size: calc(24px + 2vmin);
  font-family: 'Orbitron', sans-serif;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  text-align: center;
}

